<div class="root">
  <ng-container [ngSwitch]="state">
    <form *ngSwitchCase="ShortenerState.START" #form="ngForm" (ngSubmit)="shorten()" class="shorten-form"
      @appearDisappear autocomplete="off">
      <mat-form-field>
        <input matInput placeholder="URL" name="content" [(ngModel)]="content" #contentModel="ngModel" validUrl
          autofocus>
        <mat-error *ngIf="content && contentModel.hasError('invalidUrl')">Invalid URL</mat-error>
      </mat-form-field>
      <button mat-flat-button color="primary" type="submit" [disabled]="!form.valid">Shorten</button>
    </form>

    <div *ngSwitchCase="ShortenerState.AWAITING_RESPONSE" class="progress" @appearDisappear>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <div *ngSwitchCase="ShortenerState.LINK_RECEIVED" class="shorten-response" @appearDisappear>
      <mat-form-field fxFlex>
        <input matInput #shortUrlInput [value]="shortUrl" (click)="shortUrlInput.select()" readonly>
      </mat-form-field>
      <button mat-flat-button color="primary" [copyAndNotify]="shortUrl">Copy</button>
    </div>
  </ng-container>
</div>
