<div class="root">
  <div class="home-button">
    <button mat-button routerLink="/">bthl.es</button>
  </div>
  <button mat-button routerLink="/pages/my-links">My links</button>
  <ng-container *ngIf="unanonymousUser$ | async as unanonymousUser; else loginButton">
    <mat-spinner *ngIf="logoutPending; else logoutButton" diameter="24"></mat-spinner>
    <ng-template #logoutButton>
      <button mat-button (click)="logout()">
        Sign out of {{ unanonymousUser.displayName }}
      </button>
    </ng-template>
  </ng-container>
  <ng-template #loginButton>
    <button mat-button (click)="login()">Sign in with Google</button>
  </ng-template>
</div>
